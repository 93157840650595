import React from "react";
import {Link} from "react-router-dom";
import styles from "./item.module.css";
import Category from "../category/category";

const BlogItem = ({data}) => {
	return (
		<div className={styles.wrapper}>
			<Link className={styles.linkImage} to={`/blog/${data.slug}`}>
				<img className={styles.img} src={data.image} alt={data.title}/>
			</Link>
			<div className={styles.overlay}>
				<div className={styles.categorie}>
					<Category
						link={data.categorieSlug}
						title={data.categorie}
					/>
				</div>
				<div>
					<Link className={styles.link} to={`/blog/${data.slug}`}>
						{data.title}
					</Link>
				</div>
				<span className={styles.date}>{data.publishDate}</span>
			</div>
		</div>
	);
};

export default BlogItem;
