import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home/home";
import Blog from "./pages/blog/blog";
import BlogPost from "./pages/blog-post/blog-post";
import "./assets/css/global.module.css";
import "./assets/css/base.module.css";
import ScrollToTop from "./util/scrollToTop";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
	const scriptGoodleAnalytics = `
	<!-- DMCA.com Protection Status -->
	<script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
	<!-- Global site tag (gtag.js) - Google Analytics -->
	<script async src="https://www.googletagmanager.com/gtag/js?id=G-P88XV9PMKB"></script>
	<script>
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', 'G-P88XV9PMKB');
	</script>`;
	useEffect(() => {
		process.env.NODE_ENV === "production" &&
			document.body.insertAdjacentHTML(
				"beforeend",
				scriptGoodleAnalytics
			);
	}, [scriptGoodleAnalytics]);
	return (
		<HelmetProvider>
			<Router>
				<ScrollToTop />
				<Switch>
					<Route path="/blog/:slug">
						<BlogPost />
					</Route>
					<Route path="/category/:slug">
						<Blog />
					</Route>
					<Route path="/blog">
						<Blog />
					</Route>
					<Route path="/">
						<Home />
					</Route>
				</Switch>
			</Router>
		</HelmetProvider>
	);
};

export default App;
