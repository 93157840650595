import React from "react";
import styles from "./arrow.module.css";

const ArrowDown = ({arrow, color, bgColor}) => {
	return (
		<span
			className={`${styles.wrapper} ${arrow}`}
			style={{backgroundColor: bgColor}}
		>
			<svg
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9 3.75L9 14.25"
					stroke={color || "#8E6AF3"}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.25 9L9 14.25L3.75 9"
					stroke={color || "#8E6AF3"}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</span>
	);
};
ArrowDown.defaultProps = {
	arrow: "right",
	color: "#fff",
	bgColor: "#8e6af3",
};
export default ArrowDown;
