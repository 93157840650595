import React from "react";
import iconDmca from "../../assets/images/icon-dmca@2x.png";
import styles from "./footer.module.css";

const Footer = () => {
	return (
		<footer className={styles.wrapper}>
			<div className={styles.copyright}>
				<p>© 2021 iwritingservice.com</p>
			</div>
			<div>
				{!process.env.NODE_ENV ||
				process.env.NODE_ENV === "development" ? (
					<span className={styles.img}>
						<img src={iconDmca} alt="DMCA.com Protection Status" />
					</span>
				) : (
					<a
						href="//www.dmca.com/Protection/Status.aspx?ID=d4ef9a9b-63d0-475f-861c-853a60809d0f"
						title="DMCA.com Protection Status"
						className={styles.img}
						target="_blank"
						rel="noreferrer"
					>
						<img src={iconDmca} alt="DMCA.com Protection Status" />
					</a>
				)}
			</div>
		</footer>
	);
};

export default Footer;
