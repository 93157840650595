import React from "react";
import styles from "./social.module.css";
import iconFacebook from "../../assets/images/icon-facebook.svg";
import iconTwitter from "../../assets/images/icon-twitter.svg";

const Social = () => {
	const dataSocial = [
		{
			link: "#",
			icon: iconFacebook,
			alt: "",
		},
		{
			link: "#",
			icon: iconTwitter,
			alt: "",
		},
	];
	return (
		<ul className={styles.list}>
			{dataSocial.map((item, index) => (
				<li className={styles.item} key={index}>
					<a
						href="javascript: void(0)"
						// href={item.link}
						className={styles.link}
						// target="_blank"
						rel="noreferrer"
					>
						<img src={item.icon} alt={item.alt} />
					</a>
				</li>
			))}
		</ul>
	);
};

export default Social;
