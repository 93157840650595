import { Helmet } from "react-helmet-async";

const SEO = ({ title, description }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
		</Helmet>
	);
};

SEO.defaultProps = {
	title: "Coming Soon: International Essay Writing Service",
	description: "Coming Soon: International Essay Writing Service",
};

export default SEO;
