import React from "react";
import iconEmail from "../../../assets/images/icon-email.svg";
import styles from "./mail-box.module.css";

const MailBox = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.icon}>
				<img
					className={styles.iconImage}
					src={iconEmail}
					alt="Icon Email"
				/>
			</div>

			<div className={styles.content}>
				<p className={styles.title}>Contact us</p>
				<a
					className={styles.email}
					href="mailto:team@iwritingservice.com"
				>
					team@iwritingservice.com
				</a>
			</div>
		</div>
	);
};

export default MailBox;
