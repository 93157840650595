import React from "react";
import styles from "./subcribe.module.css";
import Button from "../button/button";

const Subcribe = () => {
	return (
		<form className={styles.form}>
			<div className={styles.inputWrapper}>
				<input
					className={styles.input}
					type="text"
					placeholder="Your email"
				/>
			</div>
			<div className={styles.button}>
				<Button title="Subcribe"/>
			</div>
		</form>
	);
};

export default Subcribe;
