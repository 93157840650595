import React from "react";
import styles from "./external-post.module.css";
import {Link} from "react-router-dom";

const ExternalPost = () => {
	const dataListPost = [
		{
			title: "ToK essays",
			link: "/",
		},
		{
			title: "Extended essays",
			link: "/",
		},
		{
			title: "MBA assignments",
			link: "/",
		},
		{
			title: "Research papers",
			link: "/",
		},
		{
			title: "Term papers",
			link: "/",
		},
		{
			title: "Theses",
			link: "/",
		},
		{
			title: "Marketing plans",
			link: "/",
		},
		{
			title: "Reports",
			link: "/",
		},
		{
			title: "Business plans",
			link: "/",
		},
	];

	return (
		<ul className={styles.list}>
			{dataListPost.map((data, index) => (
				<li className={styles.item} key={index}>
					<Link className={styles.link} to={data.link}>
						{data.title}
					</Link>
				</li>
			))}
		</ul>
	);
};

export default ExternalPost;
