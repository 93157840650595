import React from "react";
import styles from "./slide-button.module.css";
import slidePrev from "../../assets/images/arrow-slider.svg";

const PrevArrow = ({currentSlide, slideCount, ...props}) => {
	return (
		<button
			{...props}
			className={
				` ${styles.button} ${styles.prev} ` +
				(currentSlide === 0 ? `${styles.disabled}` : "")
			}
			aria-hidden="true"
			aria-disabled={currentSlide === 0 ? true : false}
			type="button"
		>
			<img src={slidePrev} alt="slider prev"/>
		</button>
	);
};

export default PrevArrow;
