import React from "react";
import Layout from "../../components/layout/layout";
import styles from "./blog-post.module.css";
import Category from "../blog/category/category";
import AboutAuthor from "./about-author/about-author";
import ButtonBack from "../../components/button-back/button-back";
import Container from "../../components/container/container";
import ContainerSmall from "../../components/container/container-small";
import img from "../../assets/images/post-detail.png";
import Info from "./info-date/post-info";
import RelatedPost from "./related-post/related-post";
import SEO from "../../components/seo/seo";

const BlogPost = () => {
	return (
		<Layout>
			<SEO/>
			<div className={styles.wrapper}>
				<ContainerSmall>
					<div className={styles.heading}>
						<div className={styles.category}>
							<Category link="#" title="Name of Category"/>
						</div>
						<h1 className={styles.title}>
							Tentang Creativity Block pada UI Designer
						</h1>
						<span className={styles.date}>March 17th, 2021</span>
					</div>
					<div className={styles.content}>
						<img src={img} alt=""/>

						<p>
							Suspendisse potenti. Praesent lorem odio, vehicula
							ultrices convallis in, convallis in mi. Duis vel
							mattis eros. Morbi eu urna dolor. Etiam mollis
							molestie justo. Sed aliquet ultrices metus, in
							pretium lacus egestas eget. Morbi convallis aliquam
							ante nec vulputate. In a volutpat massa, et interdum
							dolor. Duis consequat elit in risus volutpat, sit
							amet molestie ex accumsan. Suspendisse potenti.
							Praesent lorem odio, vehicula ultrices convallis in,
							convallis in mi. Duis vel mattis eros. Morbi eu urna
							dolor. Etiam mollis molestie justo. Sed aliquet
							ultrices metus, in pretium lacus egestas eget. Morbi
							convallis aliquam ante nec vulputate. In a volutpat
							massa, et interdum dolor. Duis consequat elit in
							risus volutpat, sit amet molestie ex accumsan.
						</p>
						<div className={styles.paddingBox}>
							<ul>
								<li>Suspendisse potenti.</li>
								<li>Praesent lorem odio.</li>
								<li>Vehicula ultrices convallis in.</li>
								<li>Duis vel mattis eros.</li>
							</ul>
							<div className={styles.highLight}>
								Suspendisse potenti. Praesent lorem odio,
								vehicula ultrices convallis in, convallis in mi.
								Duis vel mattis eros. Morbi eu urna dolor. Etiam
								mollis molestie justo.
							</div>
						</div>
						<h3>Subtitle example right here</h3>
						<p>
							Suspendisse potenti. Praesent lorem odio, vehicula
							ultrices convallis in, convallis in mi. Duis vel
							mattis eros. Morbi eu urna dolor. Etiam mollis
							molestie justo. Sed aliquet ultrices metus, in
							pretium lacus egestas eget. Morbi convallis aliquam
							ante nec vulputate. In a volutpat massa, et interdum
							dolor. Duis consequat elit in risus volutpat, sit
							amet molestie ex accumsan. Suspendisse potenti.
							Praesent lorem odio, vehicula ultrices convallis in,
							convallis in mi. Duis vel mattis eros. Morbi eu urna
							dolor. Etiam mollis molestie justo. Sed aliquet
							ultrices metus, in pretium lacus egestas eget. Morbi
							convallis aliquam ante nec vulputate. In a volutpat
							massa, et interdum dolor. Duis consequat elit in
							risus volutpat, sit amet molestie ex accumsan.
						</p>
					</div>
					<Info published="March 17, 2021" updated="March 17, 2021"/>
					<div className={styles.aboutAuthor}>
						<AboutAuthor/>
					</div>
					<div className={styles.backTo}>
						<ButtonBack title="Back to blog posts" link="/blog"/>
					</div>
				</ContainerSmall>
				<div className={styles.otherPosts}>
					<Container>
						<RelatedPost/>
					</Container>
				</div>
			</div>
		</Layout>
	);
};

export default BlogPost;
