import React from "react";
import styles from "./header.module.css";
import Logo from "./logo/logo";
import Nav from "./nav/nav";
import Container from "../container/container";

const Header = () => {
	return (
		<header className={styles.wrapper}>
			<Container>
				<div className={styles.inner}>
					<Logo/>
					{/* <Nav /> */}
				</div>
			</Container>
		</header>
	);
};

export default Header;
