import React from "react";
import {NavLink} from "react-router-dom";
import styles from "./category-filter.module.css";

const CategoryFilter = ({dataCategoryList}) => {
	return (
		<ul className={styles.list}>
			<li className={styles.item}>
				<NavLink
					to="/category/latest"
					activeClassName="active"
					className={styles.link}
				>
					Latest
				</NavLink>
			</li>
			{dataCategoryList.map((data, index) => (
				<li className={styles.item} key={index}>
					<NavLink
						className={styles.link}
						activeClassName="active"
						to={`/category/${data.categorieSlug}`}
					>
						{data.categorie}
					</NavLink>
				</li>
			))}
		</ul>
	);
};

export default CategoryFilter;
