import React from "react";
import slideNext from "../../assets/images/arrow-slider.svg";
import styles from "./slide-button.module.css";

const NextArrow = ({className, onClick}) => {
	return (
		<button
			className={
				` ${styles.button} ${styles.next} ` +
				(className.search("slick-disabled") !== -1 &&
					`${styles.disabled}`)
			}
			onClick={onClick}
		>
			<img src={slideNext} alt="slider next"/>
		</button>
	);
};

export default NextArrow;
