import React from "react";
import Layout from "../../components/layout/layout";
import img from "../../assets/images/home-img@2x.png";
import Social from "../../components/social/social";
import styles from "./home.module.css";
import Container from "../../components/container/container";
import Title from "../../components/title/title";
import MailBox from "./mail-box/mail-box";
import ExternalPost from "./external-post/external-post";
import SEO from "../../components/seo/seo";

const Home = () => {
	return (
		<Layout>
			{/* <SEO title={title} description={description} /> */}
			<SEO />
			<section className={styles.wrapper}>
				<Container>
					<div className={styles.inner}>
						<div className={styles.content}>
							<div className={styles.title}>
								<Title>
									Need help with <span>academic essays?</span>
									<br />
									We’ve got your back.
								</Title>
							</div>
							<div className={styles.paragraph}>
								<p>
									We’re a soon-to-be-launched international
									essay writing service. Our essay writers
									will be subject matter experts from leading
									universities across the UK, US, Australia,
									and Canada.
								</p>
								<p>
									Whether you’re an English native speaker or
									English as a second language student, when
									you will hire a writer from us, you can be
									sure to receive high-quality, custom
									academic papers at cheap and reasonable
									prices.
								</p>
								<p>
									Our service will be trustworthy,
									easy-to-use, fast, reliable, and most
									importantly, legit. From college and
									university to graduate and Master degree
									courses, we will offer a range of services
									such as:
								</p>
							</div>
							<ExternalPost />
							<p className={styles.smallText}>
								Coming soon — to help you write better essays
								and get the grades you desire.
							</p>
						</div>
						<div className={styles.img}>
							<div className={styles.figureWrapper}>
								<img
									className={styles.figure}
									src={img}
									alt=""
								/>
								<div className={styles.box}>
									<MailBox />
									<div className={styles.social}>
										<Social />
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</section>
		</Layout>
	);
};

export default Home;
