import React from "react";
import CategoryFilter from "./category-filter/category-filter";
import Layout from "../../components/layout/layout";
import PrimaryTitle from "../../components/title/primary-title";
import Container from "../../components/container/container";
import Subcribe from "../../components/subcribe/subcribe";
import styles from "./blog.module.css";
import imgPost from "../../assets/images/post.png";
import imgPost1 from "../../assets/images/post1.png";
import imgPost2 from "../../assets/images/post2.png";
import imgPost3 from "../../assets/images/post3.png";
import imgPost4 from "../../assets/images/post4.png";
import imgPost5 from "../../assets/images/post5.png";
import FeaturePost from "./feature-post/feature-post";
import featurePostImg from "../../assets/images/feature-post.png";
import ButtonLoadMore from "../../components/button-load-more/button-load-more";
import BlogList from "../../components/blog-list/blog-list";
import SEO from "../../components/seo/seo";

const Blog = () => {
	const dataCategoryList = [
		{categorie: "Category", categorieSlug: "other-category"},
		{categorie: "writing", categorieSlug: "writing"},
		{categorie: "content writing", categorieSlug: "content-writing"},
		{
			categorie: "editorial calendar",
			categorieSlug: "editorial-calendar",
		},
	];
	const dataPostList = [
		{
			categorie: "Category",
			categorieSlug: "other-category",
			image: featurePostImg,
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			categorieSlug: "other-category",
			image: imgPost,
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			image: imgPost1,
			categorieSlug: "other-category",
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer tang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			image: imgPost2,
			categorieSlug: "other-category",
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity",
		},
		{
			categorie: "Other category",
			categorieSlug: "other-category",
			image: imgPost3,
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			image: imgPost4,
			categorieSlug: "other-category",
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
		{
			categorie: "Other category",
			image: imgPost5,
			categorieSlug: "other-category",
			publishDate: "May, 16 2021",
			slug: "list-of-blogging-terms-you-must-know",
			title: "Tentang Creativity Block pada UI Designer",
		},
	];
	return (
		<Layout>
			<SEO/>
			<section className={styles.detail}>
				<div className={styles.categoryList}>
					<CategoryFilter dataCategoryList={dataCategoryList}/>
				</div>
				<Container>
					<div>
						<div className={styles.title}>
							<PrimaryTitle>Blog</PrimaryTitle>
						</div>
						<p className={styles.caption}>
							Stories, tips, and tools to inspire you to find your
							most creative self. Subscribe to get curated content
							delivered directly yo your inbox.
						</p>
					</div>
					<div className={styles.subcribe}>
						<Subcribe/>
					</div>
					<div className={styles.featurePost}>
						<FeaturePost data={dataPostList[0]}/>
					</div>
					<div className={styles.postList}>
						<BlogList dataPostList={dataPostList.slice(1)}/>
					</div>

					<div className={styles.btnLoadMore}>
						<ButtonLoadMore title="Load more articles"/>
					</div>
				</Container>
			</section>
		</Layout>
	);
};

export default Blog;
